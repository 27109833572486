export const TIPOS_CHAVE = {
  Despesa: 1,
  Projeto: 2,
};

export const diasDaSemana = {
  Domingo: 1,
  Segunda: 2,
  Terca: 4,
  Quarta: 8,
  Quinta: 16,
  Sexta: 32,
  Sabado: 64,
};

export const diasDaSemanaById = {
  [diasDaSemana.Domingo]: 'Dom',
  [diasDaSemana.Segunda]: 'Seg',
  [diasDaSemana.Terca]: 'Ter',
  [diasDaSemana.Quarta]: 'Qua',
  [diasDaSemana.Quinta]: 'Qui',
  [diasDaSemana.Sexta]: 'Sex',
  [diasDaSemana.Sabado]: 'Sab',
};

export const optionsFromKeyValue = (obj, isKeyNumber) =>
  Object.keys(obj).map(key => ({
    value: isKeyNumber ? Number(key) : key,
    label: obj[key],
  }));

export const diasDaSemanaOptions = optionsFromKeyValue(diasDaSemanaById, true);

export const semanaDeTrabalhoOptions = diasDaSemanaOptions.slice(1, 6);

export const caracteristicas = {
  ConfiguraAtendimento: 498,
  RealizaAgendamento: 499,
  ConverterFolhaSienge: 554,
};

export const APIBASE = 'https://api.setcorp.com.br';
//export const APIBASE = 'http://localhost:54608';
