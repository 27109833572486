import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from '~/Components/Header';
import AuthStorage from '~/Utils/AuthStorage';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import { LoadingContainer } from '~/Components/Loading/styles';
import { APIBASE } from '~/constants';
import { caracteristicas } from '~/constants';

export default function Formatador() {
  const [carregando, setCarregando] = useState(false);
  const [centroCustoSelecionado, setCentroCustoSelecionado] = useState('');

  const verificarCentroCusto = () => {
    if (centroCustoSelecionado) {
      return false;
    }
    return true;
  };

  const obterValorCentroCusto = event => {
    if (!event || event.target.value === ' ') {
      return;
    }
    setCentroCustoSelecionado(event.target.value);
  };

  const arquivoRef = React.createRef();

  const NormalizarArquivo = async arquivo => {
    try {
      if (!arquivo) {
        arquivoRef.current.Value = '';
        toast.error('Arquivo vazio.');
        return;
      }
      if (!arquivo.name.includes('.csv') && !arquivo.name.includes('.xlsx')) {
        arquivoRef.current.Value = '';
        toast.error('Extensão de arquivo inválida.');
        return;
      }

      setCarregando(true);
      let token = AuthStorage.getToken()
        ? 'Bearer ' + AuthStorage.getToken()
        : null;

      let request = new FormData();
      request.append('Arquivo', arquivo);
      request.append('CentroCusto', centroCustoSelecionado);

      let response = await Axios.post(
        APIBASE + '/exportador/normalizar-planilha-sienge',
        request,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          responseType: 'blob',
        }
      );

      if (response) {
        const header = response.headers['content-disposition'];
        const filename = header.match(/filename=(.+)/)[1];
        const blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        toast.success('Arquivo normalizado com sucesso.');
        setCentroCustoSelecionado('');
      }
    } catch (error) {
      toast.error('Não foi possível processar o arquivo enviado!');
    } finally {
      setCarregando(false);
    }
  };

  return (
    <>
      {AuthStorage.temCaracteristica(caracteristicas.ConverterFolhaSienge) && (
        <>
          <Header title="Normalizar planilha"></Header>
          <div className="alert alert-info">
            <h4 className="alert-heading">Instruções de uso</h4>
            <ol>
              <li>
                A folha de pagamento deve ser extraída do sistema Questor.
              </li>
              <li>
                Os arquivos devem estar nos formatos .xlsx ou .csv para
                viabilizar a conversão.
              </li>
              <li>A inclusão do centro de custo é obrigatória.</li>
            </ol>
          </div>
          {!carregando && (
            <div className="container mt-4 justify-content-center">
              <Row className="justify-content-center">
                <Col xs={12} md={6}>
                  <label>
                    Selecione o centro de custo.
                    <select
                      className="form-control"
                      onChange={event => {
                        obterValorCentroCusto(event);
                      }}
                    >
                      <option value="">Selecione...</option>
                      <option value="23802">23802 - Setlife Uberaba</option>
                      <option value="29104">
                        29104 - SOBRADOS ALTO PADRÃO SETSUL 2
                      </option>
                    </select>
                  </label>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12} md={6}>
                  <label htmlFor="UploadArquivo" className="mb-0">
                    <button
                      className="btn btn-primary mt-2"
                      type="button"
                      onClick={() => {
                        arquivoRef.current.click();
                      }}
                      disabled={verificarCentroCusto()}
                    >
                      Adicionar arquivo
                    </button>
                    <input
                      type="file"
                      className="d-none"
                      name="UploadArquivo"
                      id="UploadArquivo"
                      ref={arquivoRef}
                      onChange={event => {
                        NormalizarArquivo(event.target.files[0]);
                      }}
                    />
                  </label>
                </Col>
              </Row>
            </div>
          )}
          {carregando && (
            <div>
              <LoadingContainer>
                <FaSpinner className="spinner" /> Formatando arquivo...
              </LoadingContainer>
            </div>
          )}
        </>
      )}
      {!AuthStorage.temCaracteristica(caracteristicas.ConverterFolhaSienge) && (
        <div className="text-center mt-4">
          <h4> Usuário sem permissão para acessar a tela.</h4>
        </div>
      )}
    </>
  );
}
